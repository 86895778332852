import React from 'react';
import Layout from "../components/layout";
// import styled from "styled-components";
import { Helmet } from 'react-helmet';

const RandomPage = () => {

    return (
        <Layout>
            <Helmet>
                <title>3 Random 1:1 Questions</title>
                <meta name="description" content="ManagersApp Weekly Newsletter"></meta>
            </Helmet>
            <div className={"page-header home"}>
                <h1 style={{ color: "#3d84a8", fontWeight: "normal"}}>3 Random 1:1 Questions</h1>
                <p style={{ paddingLeft: "20%", paddingRight: "20%", fontSize: "14px", fontStyle:"italic"}}>
                    "Randomness is an abstract concept like infinity that exists only in concept and has no physical basis. True randomness is in fact achieved only with maximum entropy, which perhaps only exists when time is at infinity (the same as the venerated Central Limit Theory). In short, never."
                </p>
            </div>
            <div className={"container"}>
                <br />
            </div>
            <div className={"container"}>
                <div className={"container"}>
                    <iframe src="http://localhost:3000/one-on-one-questions" width="100%" height="500px"></iframe>
                    {/* <iframe src="https://app.managers.app/pulse/sdfsd" width="100%" height="700px"></iframe> */}
                </div>
            </div>
        </Layout>
    )
}


// const LearnButton = styled.button`
//   font-weight: 500;
//   font-size: 14px;
//   color: white;
//   letter-spacing: 1px;
//   height: 50px;
//   display: block;
//   margin-left: 8px;
//   text-transform: uppercase;
//   cursor: pointer;
//   white-space: nowrap;
//   background: #93abd3;
//   border-radius: 4px;
//   padding: 0px 20px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   outline: 0px;
//   &:hover {
//     box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
//   }
//   @media (max-width: ${props => props.theme.screen.md}) {
//   }
//   @media (max-width: ${props => props.theme.screen.sm}) {
//     margin-left: 0;
//   }
// `

export default RandomPage